import React from "react";
import { auth } from "../../firebaseInit";
import { useAuthState } from "react-firebase-hooks/auth";
import { Outlet } from "react-router";
import SignButton from "../SignButton/SignButton";
import { Alert, LinearProgress, Link } from "@mui/material";
import { useIsAdmin } from "../../hooks/user";

const App: React.FC<{}> = () => {
  const [user, isUserLoading] = useAuthState(auth);
  const [isAdmin, isAdminLoading] = useIsAdmin(user);

  return (
    <div className="App" style={{ width: "80%", maxWidth: "998px" ,flexWrap: "nowrap" }}>
      {isAdmin && <Outlet />}

      {!user && !isUserLoading && (
        <Alert severity="warning">
          Pro přístup do této sekce je nejprve potřeba se přihlásit.
          <SignButton />
        </Alert>
      )}

      {user && !isAdmin && !isAdminLoading && (
        <Alert severity="error">
          K administraci nemáte přístup. Pro získání práva napište na{" "}
          <Link href="mailto:filip.kochan@koala42.com">
            filip.kochan@koala42.com
          </Link>
          .
        </Alert>
      )}

      {isUserLoading && <LinearProgress />}
    </div>
  );
};

export default App;
