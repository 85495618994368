import React from "react";
import { Outlet } from "react-router";
import { Box } from "@mui/material";
import FooterKoala from "../FooterKoala/FooterKoala";
import styles from "./App.module.scss";

const App: React.FC<{}> = () => {
  return (
    <div>
      <div className={styles["bg"]}></div>
      <div className={styles["app"]}>
        <Box className={styles["box"]}>
          <Outlet />
          <FooterKoala />
        </Box>
      </div>
    </div>
  );
};

export default App;
