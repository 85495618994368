import React from "react";
import { Modal as MuiModal, Box, Button, Typography } from "@mui/material";
import { ButtonVariant, ColorVariant } from "../../types";
import styles from "./Modal.module.scss";

interface ModalProps {
  isVisible: boolean;
  handleClose: (e?: any) => void;
  handleLeft?: (e?: any) => void;
  handleRight?: (e?: any) => void;
  mainText: string;
  hasLeftButton?: boolean;
  textLeft?: string;
  textRight?: string;
  buttonsDisabled?: boolean;
  leftButtonVariant?: ButtonVariant;
  rightButtonVariant?: ButtonVariant;
  leftButtonColor?: ColorVariant;
  rightButtonColor?: ColorVariant;
}

const Modal: React.FC<ModalProps> = ({
  isVisible,
  mainText,
  handleClose,
  handleLeft = handleClose,
  handleRight = handleClose,
  textLeft = "Více",
  textRight = "Méně",
  leftButtonVariant = ButtonVariant.outlined,
  rightButtonVariant = ButtonVariant.outlined,
  leftButtonColor = ColorVariant.info, // "inherit" | "success" | "primary" | "secondary" | "error" | "info" | "warning" | undefined
  rightButtonColor = ColorVariant.info,
  buttonsDisabled = false,
  hasLeftButton = true,
}) => {
  return (
    <MuiModal
      open={isVisible}
      onClose={handleClose}
      className={styles["whole"]}
    >
      <Box className={styles["container"]}>
        <Typography component="h6" className={styles["heading"]}>
          {mainText}
        </Typography>
        <Box className={styles["buttons"]}>
          {hasLeftButton && (
            <Button
              className={styles["button"]}
              id={styles["left"]}
              disabled={buttonsDisabled}
              color={leftButtonColor}
              variant={leftButtonVariant}
              onClick={handleLeft}
            >
              {textLeft}
            </Button>
          )}
          <Button
            className={styles["button"]}
            id={styles["right"]}
            disabled={buttonsDisabled}
            color={rightButtonColor}
            variant={rightButtonVariant}
            onClick={handleRight}
          >
            {textRight}
          </Button>
        </Box>
      </Box>
    </MuiModal>
  );
};

export default Modal;
