export interface ProductType {
  name: string;
  ingredients: string;
  allergenes: string;
  price: number;
  amount: number;
  podnadpis: string;
  vegan: boolean;
  vegetarian: boolean;
  id: string;
}

export enum ProductProps {
  name = "Název produktu",
  price = "Cena",
  amount = "Kusů na skladu",
  allergenes = "Alergeny",
  ingredients = "Složení",
  podnadpis = "Podnadpis",
}
export enum ColorVariant {
  inherit = "inherit",
  success = "success",
  primary = "primary",
  secondary = "secondary",
  error = "error",
  info = "info",
  warning = "warning",
}
export enum ButtonVariant {
  outlined = "outlined",
  text = "text",
  contained = "contained",
}
