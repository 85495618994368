import React from "react";
import { Typography } from "@mui/material";
import BasicButton from "../BasicButton/BasicButton";

const NotFound = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
      <Typography component={"h1"} variant="h1">
        404
      </Typography>
      <Typography component={"p"} variant="body1">
        Bohužel, daná stránka neexistuje...
      </Typography>
      <BasicButton
        style={{ padding: "10px" }}
        toWhere="/"
        buttonText="Zpět na hlavní stránku"
      />
    </div>
  );
};

export default NotFound;
