import React from "react";
import { Button } from "@mui/material";
import firebase from "firebase/app";
import { auth } from "../../firebaseInit";
import { useAuthState } from "react-firebase-hooks/auth";
import styles from "./SignButton.module.scss"

const SignButton = () => {
  const [user] = useAuthState(auth);
  return <div>{user ? <SignOut /> : <SignIn />}</div>;
};

function SignOut(): JSX.Element {
  return (
    <Button className={styles.signOut} onClick={() => auth.signOut()}>
      Odhlásit se
    </Button>
  );
}

function SignIn() {
  const signIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  };
  return (
    <Button className={styles.signIn} onClick={signIn}>
      Přihlásit se
    </Button>
  );
}

export default SignButton;
