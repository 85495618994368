import React, { useState } from "react";
import ProductForm from "./../ProductForm/ProductForm";
import { LinearProgress, Alert, Button, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { db, storage } from "../../firebaseInit";
import { ProductType } from "../../types";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { FormikValues } from "formik";
import { useProductImageURL } from "../../hooks/product";
import _ from "lodash";

const ProductsEdit = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const productRef = db.collection("products").doc(productId);
  const [product, isProductLoading] = useDocumentData<ProductType>(productRef);
  const { imageURL: productImageURL } = useProductImageURL(productId!);
  const [isSaved, setSaved] = useState(false);
  const [shouldDeleteCurrentImage, setShouldDeleteCurrentImage] =
    useState(false);

  const handleSubmit = async (values: FormikValues) => {
    await productRef.update(_.omit(values, "image"));
    if (shouldDeleteCurrentImage) {
      await storage.ref(`/products-images/${productId}`).delete();
    }

    if (values.image) {
      await storage.ref(`/products-images/${productId}`).put(values.image);
    }
    setSaved(true);
  };

  const handleRemoveExistingImage = () => {
    setShouldDeleteCurrentImage(true);
  };

  return (
    <div>
      {isProductLoading && <LinearProgress />}
      {!product && !isProductLoading && (
        <Alert severity="error">Daný produkt neexistuje.</Alert>
      )}
      {isSaved && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Alert severity="success">Produkt byl úspěšně aktualizován.</Alert>
          <Button
            onClick={() => navigate("../")}
            variant="outlined"
            sx={{
              alignSelf: "center",
              color: "#F54749",
              border: "1px solid #F54749 !important",
              textTransform: "none",
              background: "#fff !important",
              height: "45px",
            }}
          >
            Zpět na seznam produktů
          </Button>
        </Box>
      )}
      {!isSaved && product && (
        <ProductForm
          product={product}
          productImage={productImageURL}
          handleSubmit={handleSubmit}
          handleRemoveExistingImage={handleRemoveExistingImage}
        />
      )}
    </div>
  );
};

export default ProductsEdit;
