import React, { createRef, useState } from "react";
import { Formik, FormikValues } from "formik";
import { default as OwnButton } from "../Button/Button";
import {
  TextField,
  FormLabel,
  Button,
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { ProductProps, ProductType } from "../../types";
import { isNumber, isInteger, isEmpty } from "lodash";
import {
  ALLERGENS_TITLE,
  LENGTH_INGREDIENTS,
  LENGTH_NAME,
  LENGTH_SUBTITLE,
  MAX_ALLERGENS,
} from "../../constants";

import styles from "./ProductForm.module.scss";
import AllergenIcon from "../AllergenIcon/AllergenIcon";
import { useNavigate } from "react-router";

type RealFormikValues = ProductType & { image: File | undefined };
interface ProductFormProps {
  product: ProductType;
  productImage?: string;
  handleSubmit: (values: FormikValues) => void;
  handleRemoveExistingImage?: () => void;
}

const ProductForm: React.FC<ProductFormProps> = ({
  product,
  handleSubmit,
  productImage,
  handleRemoveExistingImage,
}) => {
  const navigate = useNavigate();
  const inputRef = createRef<HTMLInputElement>();
  const [imageDeleted, setImageDeleted] = useState(false);

  const getAmountDifference = (now: number) => {
    if (product.name === "") {
      return "";
    }

    const diff = now - product.amount;
    if (diff === 0) {
      return "";
    }

    if (diff > 0) {
      return (
        <Alert icon={false} severity="success" className={styles.stockDiff}>
          (+&nbsp;{diff})
        </Alert>
      );
    }

    return (
      <Alert icon={false} severity="error" className={styles.stockDiff}>
        (-&nbsp;{Math.abs(diff)})
      </Alert>
    );
  };

  const validate = (values: FormikValues) => {
    let errors: any = {};

    if ((values.name as string).length < 3) {
      errors.name = "Název musí mít alespoň 3 znaky.";
    }
    if ((values.name as string).length >= LENGTH_NAME) {
      errors.name = "Název nemůže být delší než 25 znaků.";
    }
    if ((values.podnadpis as string).length >= LENGTH_SUBTITLE) {
      errors.podnadpis = "Podnadpis nemůže být delší než 30 znaků.";
    }
    if ((values.ingredients as string).length >= LENGTH_INGREDIENTS) {
      errors.ingredients = "Složení nemůže být delší než 300 znaků.";
    }
    if (!isNumber(values.price)) {
      errors.price = "Cena musí být číslo.";
    }
    if ((values.price as number) < 0) {
      errors.price = "Cena musí být alespoň 0 Kč.";
    }
    if (!isNumber(values.amount)) {
      errors.amount = "Počet kusů na skladu musí být číslo.";
    }
    if ((values.amount as number) < 0) {
      errors.amount = "Počet kusů na skladu musí být alespoň 0.";
    }
    if (!isInteger(values.amount)) {
      errors.amount = "Počet kusů na skladu musí být celé číslo.";
    }
    return errors;
  };

  return (
    <Formik
      initialValues={{ ...product, image: undefined } as RealFormikValues}
      validate={validate}
      onSubmit={handleSubmit}
      className={styles.main}
    >
      {({
        values,
        errors,
        handleSubmit,
        handleChange,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          <span className={styles.section}>
            <FormLabel htmlFor="name" className={styles.name}>
              {ProductProps.name}
            </FormLabel>
            <span className={styles.right}>
              <TextField
                required
                type="text"
                name="name"
                id="name"
                inputProps={{ maxLength: LENGTH_NAME }}
                onChange={handleChange}
                value={values.name}
                error={!!errors.name}
                helperText={errors.name}
                className={styles.field}
              />
            </span>
          </span>
          <span className={styles.section}>
            <FormLabel htmlFor="price" className={styles.price}>
              {ProductProps.price} (Kč)
            </FormLabel>
            <span className={styles.right}>
              <TextField
                type="number"
                name="price"
                id="price"
                onChange={handleChange}
                value={values.price}
                error={!!errors.price}
                helperText={errors.price}
                className={styles.field}
              />
            </span>
          </span>

          <span className={styles.section}>
            <FormLabel htmlFor="amount" className={styles.amount}>
              {ProductProps.amount}
            </FormLabel>
            <span className={styles.right}>
              <TextField
                type="number"
                name="amount"
                id="amount"
                onChange={handleChange}
                value={values.amount}
                error={!!errors.amount}
                helperText={errors.amount}
                className={styles.field}
              />
              {!errors.amount && getAmountDifference(values.amount)}
            </span>
          </span>
          <span className={styles.section}>
            <FormLabel htmlFor="podnadpis" className={styles.podnadpis}>
              {ProductProps.podnadpis}
            </FormLabel>
            <span className={styles.right}>
              <TextField
                type="text"
                name="podnadpis"
                inputProps={{ maxLength: LENGTH_SUBTITLE }}
                id="podnadpis"
                onChange={handleChange}
                value={values.podnadpis}
                error={!!errors.podnadpis}
                helperText={errors.podnadpis}
                className={styles.field}
              />
            </span>
          </span>

          <span className={styles.sectionIngredient}>
            <FormLabel htmlFor="ingredients" className={styles.intgredients}>
              {ProductProps.ingredients}
            </FormLabel>
            <TextField
              inputProps={{
                maxLength: LENGTH_INGREDIENTS,
                sx: { height: 100 },
              }}
              multiline
              rows={4}
              type="text"
              name="ingredients"
              id="ingredients"
              onChange={handleChange}
              value={values.ingredients}
              error={!!errors.ingredients}
              helperText={errors.ingredients}
              className={styles.field}
            />
          </span>
          <span className={styles.section}>
            <FormLabel htmlFor="ingredients" className={styles.intgredients}>
              Jídlo je
            </FormLabel>
            <span className={styles.veganButtons}>
              <Button
                onClick={(_) => {
                  setFieldValue("vegetarian", !values.vegetarian);
                  if (!values.vegetarian) {
                    setFieldValue(
                      "allergenes",
                      values.allergenes
                        .split(", ")
                        .filter((a) => !["2", "4", "14"].includes(a))
                        .sort((a, b) => Number(a) - Number(b))
                        .join(", ")
                    );
                  }
                  if (values.vegetarian) {
                    setFieldValue("vegan", false);
                  }
                }}
                className={values.vegetarian ? styles.active : styles.nonactive}
              >
                Vegetarián
              </Button>
              <Button
                onClick={(_) => {
                  setFieldValue("vegetarian", !values.vegan);
                  setFieldValue("vegan", !values.vegan);
                  if (!values.vegan) {
                    setFieldValue(
                      "allergenes",
                      values.allergenes
                        .split(", ")
                        .filter((a) => !["2", "3", "4", "7", "14"].includes(a))
                        .sort((a, b) => Number(a) - Number(b))
                        .join(", ")
                    );
                  }
                }}
                className={values.vegan ? styles.active : styles.nonactive}
              >
                Vegan
              </Button>
            </span>
          </span>

          <span className={styles.allergenContainer}>
            <FormLabel htmlFor="allergens" className={styles.allergens}>
              {ProductProps.allergenes}
            </FormLabel>
            <span className={styles.checkboxContainer}>
              <FormControl className={styles.allergenControl} id="col1">
                {new Array(MAX_ALLERGENS / 2).fill(0).map((_, i) => {
                  const allergen = i + 1;
                  const includes = values.allergenes
                    .split(", ")
                    .includes(allergen + "");
                  return (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          checked={includes}
                          sx={{
                            width: "32px !important",
                            height: "32px !important",
                            borderRadius: "8px",
                            color: "#F4A808",
                            "&.Mui-checked": {
                              color: "#F4A808",
                            },
                          }}
                          color="warning"
                          disabled={
                            ([3, 7].includes(allergen) && values.vegan) ||
                            (values.vegetarian && [2, 4].includes(allergen))
                          }
                          className={styles.checkboxes}
                        />
                      }
                      label={<AllergenIcon idIcon={allergen} />}
                      title={ALLERGENS_TITLE[i]}
                      onChange={() => {
                        if (includes) {
                          setFieldValue(
                            "allergenes",
                            values.allergenes
                              .split(", ")
                              .filter((a) => a !== String(allergen))
                              .sort((a, b) => Number(a) - Number(b))
                              .join(", ")
                          );
                        } else {
                          setFieldValue(
                            "allergenes",
                            (values.allergenes + ", " + allergen)
                              .split(", ")
                              .filter((a) => a !== "")
                              .sort((a, b) => Number(a) - Number(b))
                              .join(", ")
                          );
                        }
                      }}
                    />
                  );
                })}
              </FormControl>
              {/* sorry about this it can be done in one for loop and use flex more
              elegantly i thought i was in hurry so i did it the easier (faster) but ugly way... 
              look in git around 5.5.2022 it should be fine (readable) there...*/}
              <FormControl className={styles.allergenControl}>
                {new Array(MAX_ALLERGENS / 2).fill(0).map((_, i) => {
                  const allergen = i + 1 + 7;
                  const includes = values.allergenes
                    .split(", ")
                    .includes(allergen + "");
                  return (
                    <FormControlLabel
                      key={i + 7}
                      control={
                        <Checkbox
                          checked={includes}
                          color="warning"
                          className={styles.checkboxes}
                          sx={{
                            width: "32px !important",
                            height: "32px !important",
                            borderRadius: "8px",
                            color: "#F4A808",
                            "&.Mui-checked": {
                              color: "#F4A808",
                            },
                          }}
                        />
                      }
                      label={<AllergenIcon idIcon={allergen} />}
                      title={ALLERGENS_TITLE[i + 7]}
                      disabled={[14].includes(allergen) && values.vegetarian}
                      onChange={() => {
                        if (includes) {
                          setFieldValue(
                            "allergenes",
                            values.allergenes
                              .split(", ")
                              .filter((a) => a !== String(allergen))
                              .sort((a, b) => Number(a) - Number(b))
                              .join(", ")
                          );
                        } else {
                          setFieldValue(
                            "allergenes",
                            (values.allergenes + ", " + allergen)
                              .split(", ")
                              .filter((a) => a !== "")
                              .sort((a, b) => Number(a) - Number(b))
                              .join(", ")
                          );
                        }
                      }}
                    />
                  );
                })}
              </FormControl>
            </span>
          </span>

          <span className={styles.section}>
            <FormLabel htmlFor="image" className={styles.image}>
              Nový obrázek
            </FormLabel>
            <div className={styles.imageContainer}>
              <Button
                className={styles.imageButton}
                onClick={() => inputRef.current?.click()}
              >
                Nahrát obrázek
              </Button>
              {values.image ? (
                <>
                  <Typography component="code">{values.image.name}</Typography>
                  <OwnButton
                    style={{ marginLeft: "1rem" }}
                    onClick={(e) => {
                      e.preventDefault(); // prevents submitting the form
                      setFieldValue("image", undefined);
                    }}
                  >
                    Odebrat
                  </OwnButton>
                </>
              ) : (
                <Typography component="code">zatím nevybrán</Typography>
              )}
            </div>
          </span>

          <span className={styles.imageSection} id="imageSection">
            <FormLabel className={styles.actualImageLabel}>
              Aktuální obrázek
            </FormLabel>
            {productImage && !imageDeleted ? (
              <>
                <img
                  src={productImage}
                  alt={product.name}
                  className={styles.actualImage}
                />
                {handleRemoveExistingImage && (
                  <OwnButton
                    type="default"
                    style={{ alignSelf: "center" }}
                    onClick={(e) => {
                      e.preventDefault(); // prevents submitting the form
                      setImageDeleted(true);
                      handleRemoveExistingImage();
                    }}
                  >
                    Odebrat
                  </OwnButton>
                )}
              </>
            ) : (
              <Typography component="code" className={styles.actualImageText}>
                žádný
              </Typography>
            )}
          </span>

          <div className={styles.saveButtons}>
            <Button className={styles.button} onClick={() => navigate("../")}>
              Zavřít bez úprav
            </Button>
            <Button
              className={styles.button}
              type="submit"
              disabled={isSubmitting || !isEmpty(errors)}
            >
              Uložit
            </Button>
          </div>
          <input
            ref={inputRef}
            id="image"
            value={""}
            type="file"
            className={styles.imageInput}
            onChange={(e) => {
              if (!e.currentTarget.files) return;
              setFieldValue("image", e.currentTarget.files[0]);
            }}
            name="image"
            accept=".jpg,.jpeg,.png"
          />
        </form>
      )}
    </Formik>
  );
};

export default ProductForm;
