import React from "react";
import { Link } from "react-router-dom";
import styles from "./SnakeLink.module.scss";

interface SnakeLinkProps {
  linkText: string;
  toWhere: string;
}

export const SnakeLink: React.FC<SnakeLinkProps> = ({ linkText, toWhere }) => {
  return (
    <Link to={toWhere} className={styles["link"]}>
      {linkText}
    </Link>
  );
};
