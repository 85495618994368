import React from "react";
import styles from "./FooterKoala.module.scss";
import { ReactComponent as LogoKoala } from "./../../images/logo.svg";

const FooterKoala = () => {
  return (
    <div className={styles["container"]}>
      <a href="https://koala42.com/" target="_blank" rel="noreferrer">
        <p className={styles["made"]}>Made by</p>
        <div className={styles["content"]}>
          <LogoKoala className={styles["logo"]} />
        </div>
      </a>
    </div>
  );
};

export default FooterKoala;
