import { useEffect, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { db } from "../firebaseInit";

export const useIsAdmin = (user: any | null | undefined) => {
  const adminsRef = db.collection("admins");
  const [admins, isAdminLoading] = useCollectionData<{ uid: string }>(
    adminsRef
  );
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (isAdmin && !user) {
      setIsAdmin(false);
    }

    if (!admins || !user) return;

    if (admins.some(({ uid }) => uid === user.uid)) {
      setIsAdmin(true);
    }
  }, [admins, user, isAdmin]);

  return [isAdmin, isAdminLoading];
};
