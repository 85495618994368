import React, { HTMLProps } from "react";
import styles from "./Button.module.scss";

const Button: React.FC<{ filled?: boolean } & HTMLProps<HTMLButtonElement>> = ({
  children,
  filled,
  ...props
}) => {
  return (
    <button
      className={styles["button"] + " " + styles[filled ? "filled" : "empty"]}
      {...(props as any)}
    >
      {children}
    </button>
  );
};

export default Button;
