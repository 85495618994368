export const MAX_QR_COUNT = 78;

export const MAX_ALLERGENS = 14;

export const HERE = 1;
export const ANOTHER_SITE = 2;

export const LENGTH_INGREDIENTS = 300;
export const LENGTH_NAME = 25;
export const LENGTH_SUBTITLE = 30;

export const ALLERGENS_TITLE = [
  "OBILOVINY OBSAHUJÍCÍ LEPEK",
  "KORÝŠI",
  "VEJCE",
  "RYBY",
  "PODZEMNICE OLEJNÁ (ARAŠÍDY)",
  "SÓJOVÉ BOBY (SÓJA)",
  "MLÉKO",
  "SKOŘEPOVÉ PLODY",
  "CELER",
  "HOŘČICE",
  "SEZAMOVÁ SEMENA (SEZAM)",
  "OXID SIŘIČITÝ A SIŘIČNANY",
  "VLČÍ BOB (LUPINA)",
  "MĚKKÝŠI",
];
