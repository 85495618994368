import { Typography } from "@mui/material";
import React from "react";
import styles from "./HowWorks.module.scss";
import BasicButton from "../BasicButton/BasicButton";

export const HowWorks = () => {
  return (
    <div className={styles["main"]}>
      <div className={styles["container"]}>
        <Typography component="h1">
          <strong>Jak na to?</strong> &#129300;
        </Typography>
        <div className={styles["app"]}>
          <ul className={styles["app-steps"]}>
            <li>Jak funguje naše aplikace?</li>
            <li>
              Po&nbsp;otevření aplikace se vám načtou aktuální produkty
              v&nbsp;lednici
            </li>
            <li>
              U&nbsp;každého produktu najdete:
              <ul>
                <li>Název</li>
                <li>Seznam&nbsp;intgrediencí</li>
                <li>Obsah&nbsp;alergenů</li>
                <li>Počet&nbsp;kusů v&nbsp;lednici</li>
                <li>Cenu</li>
                <li>Tlačítko&nbsp;koupit</li>
              </ul>
            </li>
            <li>
              Po zmáčknutí tlačítka koupit si&nbsp;můžete daný produkt zaplatit
              a&nbsp;vzít z&nbsp;ednice
            </li>
            <li>
              Applikaci můžete použít jenom na&nbsp;přečtení složení
              a&nbsp;alergenů, poté zaplatit přes QR&nbsp;kód na&nbsp;produktu
            </li>
          </ul>
        </div>

        <ul className={styles["fridge"]}>
          <li>
            Jak si <strong>koupit&nbsp;jídlo z&nbsp;ledničky </strong>
            bez&nbsp;otevření&nbsp;aplikace:
          </li>
          <ul className={styles["fridge-steps"]}>
            <li>Otevřeš ledničku</li>
            <li>Vezmeš&nbsp;si co&nbsp;se&nbsp;ti&nbsp;líbí</li>
            <li>Načteš QR&nbsp;kód na&nbsp;produktu</li>
            <li>
              Zaplatíš na&nbsp;stránce, na kterou tě QR&nbsp;kód nasměroval
            </li>
            <li>Sníš&nbsp;:P</li>
          </ul>
        </ul>

        <BasicButton toWhere="./../products" buttonText="Všechno&nbsp;jasné" />
      </div>
    </div>
  );
};

export default HowWorks;
