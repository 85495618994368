import { useState, useEffect } from "react";
import { storage } from "../firebaseInit";

export const useProductImageURL = (productId: string) => {
  const [imageURL, setImageURL] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const storageRef = storage.ref(`/products-images/`);

  useEffect(() => {
    if (!productId) return;
    storageRef
      .child(productId)
      .getDownloadURL()
      .then((url) => setImageURL(url))
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false));
  }, [storageRef, productId]);

  return { imageURL, isLoading, isError };
};
