import React, { useState } from "react";
import ProductForm from "./../ProductForm/ProductForm";
import { Alert, Button, Box } from "@mui/material";
import { useNavigate } from "react-router";
import { db, storage } from "../../firebaseInit";
import { FormikValues } from "formik";
import { omit } from "lodash";

const ProductsNew = () => {
  const navigate = useNavigate();
  const productsRef = db.collection("products");
  const [isSaved, setSaved] = useState(false);

  const handleSubmit = async (values: FormikValues) => {
    const res = await productsRef.add(omit(values, ["id", "image"]));
    if (values.image) {
      await storage.ref(`/products-images/${res.id}`).put(values.image);
    }
    setSaved(true);
  };

  return (
    <div>
      {isSaved && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Alert severity="success">Produkt byl úspěšně přidán.</Alert>
          <Button
            onClick={() => navigate("../")}
            variant="outlined"
            sx={{
              alignSelf: "center",
              color: "#F54749",
              border: "1px solid#F54749 !important",
              textTransform: "none",
              background: "#fff !important",
              height: "45px",
            }}
          >
            Zpět na seznam produktů
          </Button>
        </Box>
      )}

      {!isSaved && (
        <ProductForm
          product={{
            name: "",
            podnadpis: "",
            price: 0,
            allergenes: "",
            ingredients: "",
            amount: 0,
            vegan: false,
            vegetarian: false,
            id: "",
          }}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default ProductsNew;
