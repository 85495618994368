import { Button } from "@mui/material";
import React, { useEffect, useRef, useState, useMemo } from "react";
import QRCode from "react-qr-code";
import { useReactToPrint } from "react-to-print";
import styles from "./PrintProductQR.module.scss";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import Modal from "../Modal/Modal";
import { ButtonVariant, ColorVariant } from "../../types";
import { MAX_QR_COUNT } from "../../constants";

const PrintProductQR: React.FC<{ productId: string; onlyIcon?: boolean }> = ({
  productId: value,
  onlyIcon = false,
}) => {
  const componentToPrint = useRef(null);
  const [qrCount, setQrCount] = useState(MAX_QR_COUNT / 2);
  const [shouldPrint, setShouldPrint] = useState(false);
  const [isVisible, setVisible] = useState(false);

  const handleVisibilityChange = () => {
    setVisible(!isVisible);
  };
  const handlePrint = useReactToPrint({
    content: () => componentToPrint.current,
  });

  const handlePrintQrCodes = (count: number) => () => {
    handleVisibilityChange();
    setQrCount(count);
    setShouldPrint(true);
  };

  useEffect(() => {
    if (!shouldPrint) return;

    handlePrint();
    setShouldPrint(false);
  }, [qrCount, shouldPrint, handlePrint]);

  const qrUrl = useMemo(
    () => `${window.location.origin}/products/${value}`,
    [value]
  );

  return (
    <div
      className={styles.container}
      style={{ display: onlyIcon ? "inline" : "block" }}
    >
      <Modal
        leftButtonVariant={ButtonVariant.outlined}
        rightButtonVariant={ButtonVariant.outlined}
        leftButtonColor={ColorVariant.info}
        rightButtonColor={ColorVariant.info}
        isVisible={isVisible}
        mainText={"Vyberte počet QR kódů"}
        textLeft={"Polovina papíru"}
        textRight={"Celý papír"}
        handleLeft={handlePrintQrCodes(MAX_QR_COUNT / 2)}
        handleRight={handlePrintQrCodes(MAX_QR_COUNT)}
        handleClose={handleVisibilityChange}
      />
      {shouldPrint && (
        <div className={styles["qr-container"]} ref={componentToPrint}>
          <div
            style={{
              paddingLeft: "10mm",
              paddingRight: "10mm",
              paddingBottom: "10mm",
              paddingTop: "10mm",
              width: "210mm",
              height: "296mm",
              display: "grid",
              gridTemplateColumns: "repeat(6, 1fr)",
              gridTemplateRows: "repeat(13, 1fr)",
              placeItems: "center",
            }}
            className={styles["qr-container__content"]}
          >
            {new Array(MAX_QR_COUNT).fill(null).map((_, index) => {
              if (index < qrCount) {
                return <QRCode value={qrUrl} key={index} size={50} />;
              }
              return <div style={{ visibility: "hidden" }} key={index} />;
            })}
          </div>
        </div>
      )}
      <Button onClick={handleVisibilityChange} className={styles["button"]}>
        {!onlyIcon && (
          <span style={{ fontSize: "16px" }}>Vytisknout QR&nbsp;&nbsp;</span>
        )}
        <QrCode2Icon className={styles["icon"]} />
      </Button>
    </div>
  );
};

export default PrintProductQR;
