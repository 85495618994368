import React, { useState } from "react";
import { Button } from "@mui/material";
import styles from "./BuyButton.module.scss";
import { ProductType } from "../../types";
import Modal from "../Modal/Modal";

const BuyButton: React.FC<{
  product: ProductType;
  disabled?: boolean;
  disabledReason?: string;
}> = ({ product, disabled, disabledReason }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleBuy = (e: any) => {
    if (disabled && disabledReason) {
      setModalVisible(true);
      return;
    }
  };

  const handleCloseModal = (e: any) => {
    setModalVisible(false);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {disabled && disabledReason && (
        <Modal
          isVisible={modalVisible}
          handleClose={handleCloseModal}
          mainText={disabledReason}
          textRight={"Rozumím"}
          hasLeftButton={false}
        />
      )}
      <Button
        variant={`contained`}
        className={styles["buy"] + (disabled ? " " + styles["disabled"] : "")}
        onClick={handleBuy}
      >
        Koupit
      </Button>
    </div>
  );
};

export default BuyButton;
