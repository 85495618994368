import { Typography } from "@mui/material";
import React from "react";
import styles from "./PromotionWork.module.scss";

const PromotionWork = () => {
  return (
    <div className={styles.container} style={{ gridRow: "2 / span 1" }}>
      <span className={styles["text"]}>
        <Typography component="p" className={styles["hWork"]}>
          Myslel sis, že najdeš&nbsp;jídlo? Nenene... my&nbsp;pro&nbsp;tebe
          máme&nbsp;práci
        </Typography>
        <Typography component="p" className={styles["pWorkt"]}>
          Jsme&nbsp;KOALA42. Butik, co dělá mobilní&nbsp;aplikace,
          ERP&nbsp;systémy, nebo webové&nbsp;aplikace. Třeba&nbsp;jako je tahle.
          Stále&nbsp;rozšiřujeme tým a&nbsp;třeba k&nbsp;sobě vezmeme
          i&nbsp;tebe.
        </Typography>
      </span>
      <a
        href="https://koala42.com/kariera/"
        rel="noreferrer"
        target="_blank"
        className={styles["link"]}
      >
        <span className={styles["linkBox"]}>Otevřené pozice</span>
      </a>
    </div>
  );
};

export default PromotionWork;
