import React from "react";
import obiloviny from "./../../images/obiloviny.png";
import korysi from "./../../images/korysi.png";
import vejce from "./../../images/vejce.png";
import ryby from "./../../images/ryby.png";
import arasid from "./../../images/arasidy.png";
import soja from "./../../images/soya.png";
import mleko from "./../../images/mleko.png";
import orech from "./../../images/orech.png";
import celer from "./../../images/celer.png";
import horcice from "./../../images/horcice.png";
import sezam from "./../../images/sesame.png";
import oxid from "./../../images/oxid.png";
import vlk from "./../../images/vlci_bob.png";
import mekkys from "./../../images/mekkys.png";
import styles from "./AllergenIcon.module.scss";

interface AllergenIconProp {
  idIcon: number;
}

const AllergenIcon: React.FC<AllergenIconProp> = ({ idIcon }) => {
  return (
    <span className={styles.container}>
      {idIcon === 1 && (
        <div className={styles.content}>
          <img src={obiloviny} alt="obiloviny" className={styles.icon} />
          <h2 className={styles.text}> {idIcon}. Obiloviny s lepkem</h2>
        </div>
      )}
      {idIcon === 2 && (
        <div className={styles.content}>
          <img src={korysi} alt="korysi" className={styles.icon} />
          <h2 className={styles.text}> {idIcon}. Korýši</h2>
        </div>
      )}
      {idIcon === 3 && (
        <div className={styles.content}>
          <img src={vejce} alt="vejce" className={styles.icon} />
          <h2 className={styles.text}> {idIcon}. Vejce</h2>
        </div>
      )}
      {idIcon === 4 && (
        <div className={styles.content}>
          <img src={ryby} alt="ryby" className={styles.icon} />
          <h2 className={styles.text}> {idIcon}. Ryby</h2>
        </div>
      )}
      {idIcon === 5 && (
        <div className={styles.content}>
          <img src={arasid} alt="arasid" className={styles.icon} />
          <h2 className={styles.text}> {idIcon}. Arašídy</h2>
        </div>
      )}
      {idIcon === 6 && (
        <div className={styles.content}>
          <img src={soja} alt="soja" className={styles.icon} />
          <h2 className={styles.text}> {idIcon}. Sójové boby</h2>
        </div>
      )}
      {idIcon === 7 && (
        <div className={styles.content}>
          <img src={mleko} alt="mleko" className={styles.icon} />
          <h2 className={styles.text}> {idIcon}. Mléko</h2>
        </div>
      )}
      {idIcon === 8 && (
        <div className={styles.content}>
          <img src={orech} alt="orech" className={styles.icon} />
          <h2 className={styles.text}> {idIcon}. Skořápkové plody</h2>
        </div>
      )}
      {idIcon === 9 && (
        <div className={styles.content}>
          <img src={celer} alt="celer" className={styles.icon} />
          <h2 className={styles.text}> {idIcon}. Celer</h2>
        </div>
      )}
      {idIcon === 10 && (
        <div className={styles.content}>
          <img src={horcice} alt="horcice" className={styles.icon} />
          <h2 className={styles.text}> {idIcon}. Hořčice</h2>
        </div>
      )}
      {idIcon === 11 && (
        <div className={styles.content}>
          <img src={sezam} alt="sezam" className={styles.icon} />
          <h2 className={styles.text}> {idIcon}. Sezamové semínka</h2>
        </div>
      )}
      {idIcon === 12 && (
        <div className={styles.content}>
          <img src={oxid} alt="oxid" className={styles.icon} />
          <h2 className={styles.text}> {idIcon}. Oxid siřičitý a siřičnany</h2>
        </div>
      )}
      {idIcon === 13 && (
        <div className={styles.content}>
          <img src={vlk} alt="vlk" className={styles.icon} />
          <h2 className={styles.text}> {idIcon}. Vlčí bob</h2>
        </div>
      )}
      {idIcon === 14 && (
        <div className={styles.content}>
          <img src={mekkys} alt="mekkys" className={styles.icon} />
          <h2 className={styles.text}> {idIcon}. Měkkýši</h2>
        </div>
      )}
    </span>
  );
};

export default AllergenIcon;
