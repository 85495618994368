import { db } from "../../firebaseInit";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { ProductType } from "../../types";
import { LinearProgress, Typography } from "@mui/material";
import styles from "./CustomerProductsList.module.scss";
import CustomerProductCard from "../CustomerProductCard/CustomerProductCard";
import PromotionWork from "../PromotionWork/PromotionWork";
import { SnakeLink } from "./../SnakeLink/SnakeLink";
import React from "react";
import { Outlet } from "react-router";

const CustomerProductsList = () => {
  const productsRef = db.collection("products");
  const [products] = useCollectionData<ProductType>(productsRef, {
    idField: "id",
  });
  return (
    <div className={styles["container"]}>
      <Outlet />
      <span className={styles["upper"]}>
        <span className={styles["greet"]}>
          <Typography variant="h2" component="h2">
            Dobrý den,{" "}
            <span className={styles["h2__bold"]}>co si dnes dáte?</span>{" "}
            &#128075;
          </Typography>
        </span>
        <span className={styles["icons"]}>
          <SnakeLink toWhere="./../help" linkText="Jak to funguje?" />

          <span style={{ margin: "0 8px", color: "#707070" }}>|</span>
          <SnakeLink toWhere="./../about" linkText="O nás" />
        </span>
      </span>

      {!products && <LinearProgress />}
      {products && (
        <div className={styles["product-list"]}>
          {products
            .filter(({ amount }) => amount > 0)
            .map((product, i) => (
              <React.Fragment key={product.id}>
                <CustomerProductCard product={product} />
              </React.Fragment>
            ))}
          <PromotionWork />
        </div>
      )}
    </div>
  );
};

export default CustomerProductsList;
