import React from "react";
import { Outlet, useParams } from "react-router";
import { Typography } from "@mui/material";
import PrintProductQR from "../PrintProductQR/PrintProductQR";
import SignButton from "../SignButton/SignButton";
import styles from "./Products.module.scss";

const Products = () => {
  const { productId } = useParams();
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        {productId !== undefined ? (
          <span className={styles.edit}>
            {/*console.log("here: " + window.location.pathname)*/}
            <Typography className={styles.prod} component="h2">
              Upravit produkt
            </Typography>
            <div className={styles["print-qr"]}>
              <PrintProductQR productId={productId} />
            </div>
          </span>
        ) : (
          <span className={styles.noQr}>
            {/*console.log("tady: " + window.location.pathname)*/}
            {"/auth/products/new" === window.location.pathname ? (
              <Typography className={styles.prod} component="h2">
                Nový produkt
              </Typography>
            ) : (
              <Typography className={styles.prod} component="h2">
                Produkty
              </Typography>
            )}
          </span>
        )}
        <SignButton />
      </div>
      <Outlet />
    </div>
  );
};

export default Products;
