import React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useNavigate, useParams } from "react-router";
import { db } from "../../firebaseInit";
import { Modal } from "@mui/material";
import { ProductType } from "../../types";
import { LinearProgress, Alert, Typography, Button } from "@mui/material";
import { useProductImageURL } from "../../hooks/product";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import styles from "./CustomerProductDetail.module.scss";
import { ReactComponent as Leaf } from "./../../images/icoLeaf.svg";
import CloseIcon from "@mui/icons-material/Close";
import BuyButton from "../BuyButton/BuyButton";

const CustomerProductDetail: React.FC<{}> = () => {
  const navigate = useNavigate();
  const { productId } = useParams();
  const productRef = db.collection("products").doc(productId);
  const [product, isProductLoading] = useDocumentData<ProductType>(productRef);
  const { imageURL, isError } = useProductImageURL(productId!);

  return (
    <Modal
      open={true}
      onClose={() => navigate("../")}
      className={styles["modal"]}
      sx={{ border: "none !important" }}
    >
      <div className={styles["container"]}>
        <div className={styles["inside"]}>
          {!product && !isProductLoading && (
            <Alert severity="error">Daný produkt neexistuje.</Alert>
          )}
          {product && product.amount < 1 && (
            <Alert severity="error">
              Bohužel, daný produkt právě není dostupný.
            </Alert>
          )}

          <span className={styles["image"]}>
            <Button className={styles["close"]} onClick={() => navigate("../")}>
              <CloseIcon />
            </Button>
            {isProductLoading && <LinearProgress />}
            {product && !imageURL && (
              <span
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <BrokenImageIcon fontSize="large" />
                <Typography component="h3">Obrázek není dostupný</Typography>
              </span>
            )}
            {product &&
              product.vegetarian &&
              (product.vegan ? (
                <div className={styles.vegan}>Vegan</div>
              ) : (
                <div className={styles.veget}>Vegetarián</div>
              ))}
            {!isError && product && imageURL && (
              <img sizes="cover" src={imageURL} alt={product.name} />
            )}
          </span>

          {product && product.amount >= 1 && (
            <span className={styles["detail"]}>
              <Typography
                variant="h5"
                component="h5"
                className={styles["name"]}
              >
                {product.name}
              </Typography>
              <Typography
                variant="h6"
                component="h6"
                className={styles["subtitle"]}
              >
                {product.podnadpis}
              </Typography>

              <span className={styles["allergen"]}>
                <Leaf className="leaf" />
                <Typography
                  variant="h6"
                  component="h6"
                  className={styles["allergen-heading"]}
                >
                  {product.allergenes ? product.allergenes : "-"}
                </Typography>
              </span>

              <Typography
                variant="h4"
                component="h4"
                className={styles["ingredients"]}
              >
                {product.ingredients}
              </Typography>

              <span className={styles["bot-buy-section"]}>
                <span className={styles["text-buy-section"]}>
                  <Typography
                    variant="h6"
                    component="h6"
                    className={styles["amount"]}
                  >
                    {product.amount} ks v lednici
                  </Typography>

                  <Typography
                    variant="h6"
                    component="h6"
                    className={styles["price"]}
                  >
                    {product.price}
                    <span className={styles["kc"]}> Kč</span>
                  </Typography>
                </span>

                <BuyButton
                  product={product}
                  disabled={true}
                  disabledReason={
                    "Pro zakoupení naskenujte QR kód přímo z produktu."
                  }
                />
              </span>
            </span>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default CustomerProductDetail;
