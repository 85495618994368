import React from "react";
import { ProductType } from "../../types";
import { LinearProgress, Typography } from "@mui/material";
import { useProductImageURL } from "../../hooks/product";
import styles from "./CustomerProductCard.module.scss";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";
import { ReactComponent as Leaf } from "./../../images/icoLeaf.svg";
import { useNavigate } from "react-router";
import BuyButton from "../BuyButton/BuyButton";

interface CustomerProductCardProps {
  product: ProductType;
}

const CustomerProductCard: React.FC<CustomerProductCardProps> = ({
  product,
}) => {
  const { imageURL, isLoading, isError } = useProductImageURL(product.id!);
  const navigate = useNavigate();

  const handleNavigateToProduct = (e: React.MouseEvent) => {
    navigate(product.id);
  };

  return (
    <div className={styles["container"]} onClick={handleNavigateToProduct}>
      <span className={styles["image"]}>
        {isLoading && <LinearProgress />}
        {(isError || (!isError && !imageURL)) && (
          <div
            style={{
              position: "inherit",
              paddingTop: "10vh",
              textAlign: "center",
            }}
          >
            <BrokenImageIcon fontSize="large" />
            <Typography component="h3">Obrázek není dostupný</Typography>
          </div>
        )}
        {product.vegetarian &&
          (product.vegan ? (
            <div className={styles.vegan}>Vegan</div>
          ) : (
            <div className={styles.veget}>Vegetarián</div>
          ))}
        {!isError && imageURL && <img src={imageURL} alt={product.name} />}
      </span>

      <span className={styles["detail"]}>
        <Typography variant="h5" component="h5" className={styles["name"]}>
          {product.name ?? "Naše dobrota"}
        </Typography>

        <Typography
          variant="h6"
          component="h6"
          className={styles["ingredient"]}
        >
          {product.podnadpis ?? "Ty nejlepší suroviny"}
        </Typography>

        <span className={styles["allergen"]}>
          <Leaf className="leaf" />
          <Typography
            variant="h6"
            component="h6"
            className={styles["allergen-heading"]}
          >
            {product.allergenes ?? "-"}
          </Typography>
        </span>

        <span className={styles["bot-buy-section"]}>
          <span className={styles["text-buy-section"]}>
            <Typography
              variant="h6"
              component="h6"
              className={styles["amount"]}
            >
              {product.amount} ks v lednici
            </Typography>

            <Typography variant="h6" component="h6" className={styles["price"]}>
              {product.price}
              <span className={styles["kc"]}> Kč</span>
            </Typography>
          </span>

          <BuyButton
            product={product}
            disabled
            disabledReason={"Pro zakoupení naskenujte QR kód přímo z produktu."}
          />
        </span>
      </span>
    </div>
  );
};

export default CustomerProductCard;
