import React, { CSSProperties } from "react";
import styles from "./BasicButton.module.scss";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

interface BasicButtonProps {
  toWhere: string;
  buttonText: string;
  style?: CSSProperties;
}

const BasicButton: React.FC<BasicButtonProps> = ({
  toWhere,
  buttonText,
  style,
}) => {
  return (
    <Link className={styles["link"]} to={toWhere}>
      <Typography component="p" className={styles["link-text"]} style={style}>
        {buttonText}
      </Typography>
    </Link>
  );
};

export default BasicButton;
