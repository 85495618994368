import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App/App";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./index.scss";

import "@fontsource/poppins";
import "@fontsource/lexend"; // Defaults to weight 400. Lexend
import ProductsIndex from "./components/ProductsIndex/ProductsIndex";
import ProductsNew from "./components/ProductsNew/ProductsNew";
import ProductsEdit from "./components/ProductsEdit/ProductsEdit";
import Products from "./components/Products/Products";
import Auth from "./components/Auth/Auth";
import CustomerProductsList from "./components/CustomerProductsList/CustomerProductsList";
import CustomerProductDetail from "./components/CustomerProductDetail/CustomerProductDetail";
import HowWorks from "./components/HowWorks/HowWorks";
import About from "./components/About/About";
import NotFound from "./components/NotFound/NotFound";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Navigate to="products" replace />} />

          <Route path="products" element={<CustomerProductsList />}>
            <Route path=":productId" element={<CustomerProductDetail />} />
          </Route>

          <Route path="help">
            <Route index element={<HowWorks />} />
          </Route>

          <Route path="about">
            <Route index element={<About />} />
          </Route>

          <Route path="auth" element={<Auth />}>
            {/** unless index auth page is created */}
            <Route index element={<Navigate to="products" replace />} />

            <Route path="products" element={<Products />}>
              <Route path="new" element={<ProductsNew />} />
              <Route path=":productId" element={<ProductsEdit />} />
              <Route index element={<ProductsIndex />} />
            </Route>
          </Route>

          <Route path="admin" element={<Navigate to="../auth" replace />} />

          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
