import React from "react";
import styles from "./About.module.scss";
import { Typography } from "@mui/material";
import BasicButton from "../BasicButton/BasicButton";

const About = () => {
  return (
    <div className={styles["main"]}>
      <div className={styles["container"]}>
        <Typography component="h1" className={styles["heading"]}>
          <strong>Od koho si jídlo kupujete?</strong>
        </Typography>
        <div className={styles["section-text"]}>
          <p className={styles["text"]}>
            Zde je vše vyráběno a&nbsp;připravováno s&nbsp;péčí. Naše nabídka
            byla zrozena z&nbsp;lásky ke&nbsp;kávě a&nbsp;všemu co k tomu patří.
            Používáme jen ty nejkvalitnější suroviny od nejlepších
            a&nbsp;ověřených dodavatelů. K&nbsp;tomu přidáváme radost
            z&nbsp;naší práce a&nbsp;proto nemůže vzniknout nic jiného než
            výborný šálek kávy, čaje, čokolády nebo limonády. S&nbsp;kávou máme
            zkušenosti Dobře připravenou kávu dělají 4&nbsp;základní parametry –
            kvalitní&nbsp;italská&nbsp;káva + ruce&nbsp;zkušených&nbsp;baristek
            + kvalitní&nbsp;voda + profesionální&nbsp;stroj.
            TO&nbsp;VŠE&nbsp;VÁM&nbsp;NABÍZÍME...
          </p>
          <p className={styles["text"]}>
            Přidáme k&nbsp;tomu úsměv a&nbsp;dobrou náladu a&nbsp;každý
            se&nbsp;musí v&nbsp;naší kavárně cítit perfektně. Kavárna není jen
            místo, kde si zajdete občas na kávu. Kavárny od nepaměti
            ztělesňovaly prostředí, kde se scházeli intelektuálové, spisovatelé,
            umělci nebo třeba milenci, aby zde trávili volný čas, debatovali,
            přicházeli na nové myšlenky nebo psali nové básně. Dnes je to
            podobné, stále se v&nbsp;kavárnách setkávají přátelé, obchodní
            partneři nebo umělci, stále se zde rodí nové myšlenky nebo nové
            lásky. My uděláme vše proto, aby se zde všichni cítili příjemně
            a&nbsp;rádi se k&nbsp;nám vraceli.
          </p>
        </div>
        <BasicButton toWhere="./../products" buttonText="Na&nbsp;produkty" />
      </div>
    </div>
  );
};

export default About;
