import React, { useState } from "react";
import {
  TableContainer,
  Table,
  // Typography,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Button,
  Alert,
} from "@mui/material";
import { db, storage } from "../../firebaseInit";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  ButtonVariant,
  ColorVariant,
  ProductProps,
  ProductType,
} from "../../types";
import Modal from "../Modal/Modal";
import { useNavigate } from "react-router";
import PrintProductQR from "../PrintProductQR/PrintProductQR";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import styles from "./ProductsIndex.module.scss";

const ProductsIndex = () => {
  const productsRef = db.collection("products");
  const [products] = useCollectionData<ProductType>(productsRef, {
    idField: "id",
  });
  const [idToDelete, setIdToDelete] = useState<string>();
  const [modlaButtonsDisabled, setModlaButtonsDisabled] = useState(false);
  const [isConfirmVisible, setConfirmVisible] = useState(false);
  const navigate = useNavigate();
  const handleVisibilityChange = () => {
    setConfirmVisible(!isConfirmVisible);
  };
  const handleDelete = async () => {
    if (!idToDelete) return;
    setModlaButtonsDisabled(true);

    const toBeDeletedRef = db.collection("products").doc(idToDelete);
    await Promise.all([
      toBeDeletedRef.delete(),
      storage
        .ref(`/products-images/${idToDelete}`)
        .delete()
        .catch(() => {
          /* image doesnt exist... */
        }),
    ]);
    setModlaButtonsDisabled(false);
    handleVisibilityChange();
  };
  return (
    <span style={{ margin: "0" }}>
      <Modal
        leftButtonVariant={ButtonVariant.outlined}
        rightButtonVariant={ButtonVariant.outlined}
        leftButtonColor={ColorVariant.error}
        rightButtonColor={ColorVariant.success}
        mainText="Opravdu chcete odstranit tuto položku?"
        textRight="Ne, ponechat"
        textLeft="Ano, odstranit"
        handleRight={handleVisibilityChange}
        handleLeft={handleDelete}
        isVisible={isConfirmVisible}
        buttonsDisabled={modlaButtonsDisabled}
        handleClose={handleVisibilityChange}
      />
      {products && (
        <TableContainer className={styles["container"]}>
          <Table className={styles["table"]}>
            <TableHead className={styles["table-head"]}>
              <TableRow className={styles["head-row"]}>
                <TableCell>{ProductProps.name}</TableCell>
                <TableCell>{ProductProps.price}</TableCell>
                <TableCell>{ProductProps.amount}</TableCell>
                <TableCell>{ProductProps.allergenes}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="contained"
                    onClick={() => navigate("new")}
                    className={styles["add-button"]}
                  >
                    Přidat nový +
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={styles["table-body"]}>
              {products.map(
                ({ price, name, amount, podnadpis, allergenes, id }) => {
                  return (
                    <TableRow
                      key={price + name + amount}
                      className={styles["row"]}
                    >
                      <TableCell>{name}</TableCell>
                      <TableCell>{price} Kč</TableCell>
                      <TableCell>
                        {amount === 0 ? (
                          <Alert
                            severity="error"
                            style={{
                              color: "#F54749",
                              background: "#FEECEC",
                              borderRadius: "8px",
                            }}
                          >
                            {amount} ks
                          </Alert>
                        ) : (
                          <>{amount} ks</>
                        )}
                      </TableCell>
                      <TableCell>{allergenes}</TableCell>
                      <TableCell align="right">
                        <PrintProductQR productId={id} onlyIcon />
                        <Button
                          sx={{ marginLeft: "1rem" }}
                          color="warning"
                          variant="outlined"
                          onClick={() => navigate(id)}
                          className={styles["edit-button"]}
                        >
                          <EditIcon className={styles["edit"]} />
                        </Button>
                        <Button
                          sx={{ marginLeft: "1rem" }}
                          color="error"
                          variant="outlined"
                          onClick={() => {
                            setIdToDelete(id);
                            handleVisibilityChange();
                          }}
                          className={styles["delete-button"]}
                        >
                          <Delete className={styles["delete"]} />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!products && <LinearProgress />}
    </span>
  );
};

export default ProductsIndex;
